<template>
  <tr>
    <td>{{ request.startDate | toShortDateString }}</td>
    <td>{{ request.startDate | toLongDateString }}</td>
    <td>{{ request.duration }}</td>
    <td>{{ request.holidays }}</td>
    <td>{{ request.resumptionDate | toShortDateString }}</td>
    <td>{{ request.resumptionDate | toLongDateString }}</td>
    <td>
      <span class="action-btn mr-2 font-weight-bold h5" :style="{visibility: showEditButton ? 'visible' : 'hidden'}" @click="editRequest">&#x270E;</span>
    </td>
    <!--<td v-else></td>-->
  </tr>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>

  import { toLongDateString, toShortDateString } from '@/utils/date'
  import eventBus from '@/utils/eventBus'
  import { EDIT_REQUEST_EVENT } from '@/utils/constants'


  export default {
    name: 'vacation-entry-display',
    props: {
      request: {
        type: Object,
        required: true,
      },
      showEditButton: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    filters: {
      toLongDateString,
      toShortDateString
    },
    methods: {
      editRequest() {
        eventBus.$emit(EDIT_REQUEST_EVENT, this.request)
      }
    }
  }
</script>
